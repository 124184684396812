import React from "react"
import Menu from "./menu.js"
import Obfuscate from "react-obfuscate"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import "./layout.css"
import logo from "./logo.svg"
import mail from "./mail.svg"
import fb from "./facebook.svg"
import insta from "./instagram.svg"
import youtube from "./youtube.svg"

const Index = () => {
  const data = useStaticQuery(graphql`
    query ghostPage {
      ghostSettings {
        meta_title
        meta_description
      }
    }
  `)
  return (
    <>
      <main>
        <GatsbySeo
          title={data.ghostSettings.meta_title}
          description={data.ghostSettings.meta_description}
        />
        <div className="bg-white overflow-hidden position-fixed">
          <div className="max-w-screen-xl mx-auto">
            <div
              className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl
                lg:w-full lg:pb-28 xl:pb-32"
            >
              <div className="pt-6 px-4 sm:px-6 lg:px-8"></div>
              <div
                className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16
                  lg:mt-20 lg:px-8 xl:mt-28"
              ></div>
              <img
                className="lg:hidden h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
                src={logo}
                alt="Logo"
              />
              <div className="text-center">
                <h2
                  className="text-xl tracking-tight leading-10 font-bold
         text-gray-900 sm:text-4xl leading-none whitespace-no-wrap"
                >
                  <span>Hung Gar Kuen Berlin e.V.</span>
                </h2>
                <h3 className="text-l tracking-tight leading-10 font-bold text-gray-900 sm:text-3xl leading-none whitespace-no-wrap">
                  <span>Kung Fu der Lam-Familie</span>
                </h3>
              </div>
              <nav className="">
                <div className="text-center py-4">
                  <Menu />
                </div>
                <div className="flex justify-center space-x-4">
                  <a
                    className=""
                    href="https://de-de.facebook.com/pg/hunggarberlinde-455141971200145/posts/"
                  >
                    <img className="h-6" src={fb} alt="Link zu Facebook" />
                  </a>
                  <a
                    className="h-6"
                    href="https://www.instagram.com/hung_gar_kuen_berlin_e.v/"
                  >
                    <img className="h-6" src={insta} />
                  </a>
                  <a
                    className=""
                    href="https://www.youtube.com/channel/UCce_4nUPWN1MgpGBc_el_Uw"
                  >
                    <img className="h-6" src={youtube} />
                  </a>
                  <Obfuscate email="kontakt@hunggar.uber.space">
                    <img className="h-6" src={mail} />
                  </Obfuscate>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div
          className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-red-800"
          style={{ clipPath: "polygon(10% 0, 100% 0%, 100% 100%, 0 100%" }}
        >
          <img
            className="hidden md:block h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={logo}
            alt=""
          />
        </div>
      </main>
    </>
  )
}

export default Index
